@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@layer base {
    @font-face {
        font-family: "Pretendard";
        src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff") format("woff");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Pretendard";
        src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Pretendard";
        src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff") format("woff");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Pretendard";
        src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff") format("woff");
        font-weight: 600;
        font-style: normal;
    }
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255; 
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
    color: rgb(var(--foreground-rgb));
    background: #fff;
    /* overflow: hidden; */
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    /* padding-bottom: 50px;  */
}

/* zoom in / zoom out prevent ios */
body {
    touch-action: pan-x pan-y;
    /* background: #f2f2f2; */
}
/* zoom in / zoom out prevent ios */

/* ::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #DDDDDD;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #AAAAAA;
} */

.production-swiper {
    padding-left: 0;
}
.production-swiper .swiper-pagination-bullets {
    display: flex;
    position: static;
    padding-top: 84px;
    width: 100%;
}
.production-swiper .swiper-pagination-bullet {
    flex: 1;
    border-radius: 0px;
    margin: 0px !important;
    height: 4px;
}

.production-swiper .swiper-pagination-bullet-active {
    background-color: #ff4471;
}
.rounded-tab-container::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 600px) {
    .production-swiper {
        padding-left: 16px !important;
    }
    .production-swiper .swiper-pagination-bullet {
        height: 2px;
    }
    .production-swiper .swiper-pagination-bullets {
        padding-top: 24px;
    }
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.counter-button::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.product-detail-editor ul {
    padding: 0 16px;
}
.product-detail-editor ol {
    padding: 0 16px;
}
.product-detail-editor ul li {
    list-style: initial;
}
.product-detail-editor ol li {
    list-style: auto;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    padding-left: 16px;
    padding-right: 16px;
}

/* CustomCheckbox.css */

.custom-checkbox {
    display: inline-block;
}

.checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-icon {
    width: 16px;
    height: 16px;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
}

.checkbox-icon.checked {
    background-color: #ff84a2; /* 체크된 상태의 배경색을 지정합니다. */
    background-image: url("/public/images/IconCheckedMark.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
}
.checkbox-icon.disabled {
    background-color: #dddddd; /* 체크된 상태의 배경색을 지정합니다. */
    background-image: url("/public/images/IconCheckedMark.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
}

/* 숨겨진 input 요소를 처리하기 위한 스타일 */
.customInputHidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
}

.mySwiper .swiper-pagination-bullet-active {
    background: #ff4471;
}

.InfoCashSwiper .swiper-pagination-bullet-active {
    background: #111111;
}
.InfoCashSwiper .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
}

/** custom **/
/* [class^="gradient-bg"] {
    width: 100%;
    z-index: -1;
    position: absolute;
} */
.gradient-bg-top {
    height: 40vh;
    /* top: -100px; */
    background: linear-gradient(180deg, #ffbccc 0%, rgba(255, 255, 255, 0) 100%);
}
.gradient-bg-bottom {
    height: 40vh;
    /* bottom: -100px; */
    background: linear-gradient(360deg, #ffbccc -28.34%, rgba(255, 255, 255, 0) 100%);
}

/* input:focus-visible {
    outline-width: 0px !important;
    -webkit-outline-width: 0px !important;
} */

input:focus,
input:focus-visible {
    outline: none;
    outline-width: 0px;
}
